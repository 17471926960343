<template>
  <div class="drama">
    <div class="drama_main" v-loading='mainLoading'>
      <div class="drama_main_l">
        <div class="tabs">
          <el-radio-group v-model="tabCurrent" size="small">
            <el-radio-button class="" v-for="(item, index) in tabList" :key="index" :label="item.value">{{ item.label }}</el-radio-button>
          </el-radio-group>
        </div>
        <div class="line"></div>
        <div v-show="tabCurrent == 1">
          <div class="cover">
            <el-image style="width: 100%; height: 100%" :src="form.cover_url" fit="cover"></el-image>
            <div class="cover_b" @click="openCover">
              专辑封面
            </div>
          </div>
          <el-form :model="form" :rules="rules" ref="form" label-width="0px">
            <el-form-item prop="name">
              <div class="tit"><span>*</span>专辑名称</div>
              <div class="input">
                <el-input v-model="form.name" placeholder="请输入专辑名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="tit">选择分类</div>
              <div class="input">
                <el-cascader :props="projectTypeProps" style="width: 100%;" size="small"
                  v-model="form.category_path"
                  :key="editCascader"
                  @change='changeBtn'
                  placeholder="请选择分类">
                </el-cascader>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="drama-list" v-show="tabCurrent == 2">
          <div class="search-item">
            <!-- <el-input class="ipt" placeholder="输入标题名称" prefix-icon="el-icon-search" clearable v-model="keyword"
                            @blur="search"></el-input> -->
            <el-autocomplete class="ipt" v-model="keyword" :fetch-suggestions="querySearchAsync" placeholder="请输入标题名称"
              @select="handleSelect" suffix-icon='el-icon-search' value-key='name'></el-autocomplete>
            <div class="plus-btn" @click="addBtn">
              <span class="el-icon-plus"></span>
            </div>
          </div>
          <div class="list-item" :class="{ active: index == dramaIndex }" v-for="(item, index) in drama_list"
            :key="index" @click="selectDrama(index)">
            <el-input class="ipt" placeholder="输入标题名称" size="small" clearable v-model="item.name"></el-input>
            <div class="del-btn" @click="delBtn(index)">
              <span class="el-icon-delete"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="drama_main_r">
        <upload-btn @analysisSuccess="analysisSuccess" @analysisText="analysisText"></upload-btn>
        <div class="tit">台本文本：</div>

        <el-input style="width: 100%;" type="textarea" placeholder="请输入内容" v-model="description" rows="18"
          maxlength="300" @blur="descriptionBlur">
        </el-input>
        <div class="tit">台本录音：</div>
        <div class="audio_block" v-if="sound_url">
          <audio ref="audio" controls="controls" :src="sound_url"></audio>
          <el-button class="btn" size="small" type="primary" v-if="description != ''">重新合成</el-button>
        </div>
        <div class="audio_block" v-else>
          <p v-if="description == ''">快来填写文本，生成有声台本吧</p>
          <p v-else>待解析录音，开始<span @click="analysis">解析</span>吧～</p>
        </div>
      </div>
    </div>
    <div class="drama_footer">
      <el-button class="btnBgColor_blue_empty" size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button class="btnBgColor_blue" size="small" type="primary" :loading="loading" @click="confirm('form')">保
        存</el-button>
    </div>
    <set-cover ref="setCover" @editCover="editCover"></set-cover>
    <analysis-audio ref="analysisAudio"></analysis-audio>
  </div>
</template>

<script>
  import SetCover from '../components/set_cover/index.vue' //更换专辑封面
  import AnalysisAudio from '../components/analysis_audio/index.vue' //解析音频
  import UploadBtn from '../components/upload_btn/index.vue' //新增解析

  export default {
    components: {
      SetCover,
      AnalysisAudio,
      UploadBtn
    },
    props: {

    },
    data() {
      return {
        mainLoading: false,
        tabList: [{
            label: '基本信息',
            value: 1
          },
          {
            label: '台本列表',
            value: 2
          },
        ],
        tabCurrent: 1,
        loading: false,
        editCascader:0,
        projectTypeProps: {
          lazy: true,
          checkStrictly: true,
          lazyLoad: (node, resolve) => {
            this.typeLazyLoad(node, resolve);
          }
        },
        form: {
          name: '',
          category_id: '',
          category_path:[],
          cover_url: ''
        },
        sound_url: '',
        description: '',
        rules: {
          name: [{
            required: true,
            message: '请输入专辑名称',
            trigger: 'blur'
          }, ],
        },
        options: [],
        drama_list: [],
        keyword: '',
        dramaIndex: 0,
        id: null,
      };
    },
    computed: {

    },
    created() {

    },
    mounted() {
      if (this.$route.params.id) {
        this.$nextTick(() => {
          if (this.$route.params.id == -1) {
            // 创建
            return
          } else if (this.$route.params.id == -2) {
            // 引用
            this.quoteDetails(this.$route.query.id)
          } else {
            var name = '编辑台本专辑'
            var key = this.$route.meta.key + '_' + this.$route.params.id
            this.$store.dispatch('tabsArr/getName', {
              name,
              key
            })
            // 编辑
            this.id = this.$route.params.id
            this.getDetails()
          }
        })
      }
    },
    watch: {

    },
    methods: {
      // 解析其余成功
      analysisText(val) {
        if (!this.drama_list.length) return
        this.drama_list[this.dramaIndex].description = val
        this.description = this.drama_list[this.dramaIndex].description
      },
      // 解析链接成功
      analysisSuccess(val, type) {
        if (!this.drama_list.length) return

        if (type == 1) {
          this.drama_list[this.dramaIndex].description = val
          this.description = this.drama_list[this.dramaIndex].description
        } else {
          this.drama_list[this.dramaIndex].sound_url = val
          this.sound_url = this.drama_list[this.dramaIndex].sound_url
        }
      },
      // 解析音频
      analysis() {
        this.$refs.analysisAudio.open()
      },
      // 修改封面
      openCover() {
        this.$refs.setCover.open()
      },
      // 封面
      editCover(val) {
        this.form.cover_url = val
      },
      confirm(formName) {
        var that = this
        if (!this.drama_list.length) {
          this.$errMsg('台本列表不能为空！')
          return
        }
        let d = this.drama_list.some((item) => {
          if (item.description == '' && item.sound_url == '') {
            return true
          }
          if (item.name == '') {
            return true
          }
        })
        if (d) {
          this.$errMsg('请输入台本文本信息或音频信息')
          return
        }

        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            let obj = {
              drama_list: JSON.stringify(this.drama_list),
              ...this.form,
            }
            if (this.id) {
              obj.id = this.id
              // 编辑
              this.$workbenApi.updateConvertAlbum(obj).then(res => {
                this.loading = false;
                if (res.code == 1000) {
                  this.$succMsg(res.message)
                  setTimeout(function() {
                    that.$store.dispatch('tabsArr/delTabList')
                  }, 2000)
                } else {
                  this.$errMsg(res.message)
                }
              })
            } else {
              // 新增
              this.$workbenApi.addConvertAlbum(obj).then(res => {
                this.loading = false;
                if (res.code == 1000) {
                  this.$succMsg(res.message)
                  setTimeout(function() {
                    that.$store.dispatch('tabsArr/delTabList')
                  }, 2000)
                } else {
                  this.$errMsg(res.message)
                }
              })
            }

          } else {
            return false
          }
        });
      },
      //播放组件
      handlePlay(row) {
        this.src = row.filePath;
        this.play();

      },
      //播放
      play() {
        this.dialogVisible = true;
        this.$refs.audio.play();
      },
      //音频暂停
      stop() {
        this.dialogVisible = false;
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
      },
      addBtn() {
        let obj = {
          name: `${this.drama_list.length + 1}.新建台本`,
          sound_url: '',
          description: '',
        }
        this.drama_list.push(obj)
      },
      delBtn(index) {
        this.drama_list.splice(index, 1)
      },
      // 获取专辑列表
      getAlbumList() {
        this.$workbenApi.albumList().then(res => {
          if (res.code == 1000) {
            this.options = res.result
          }
        })
      },
      // 动态加载分类
      typeLazyLoad(node, resolve) {
        if(!this.editCascader) return
        const { level } = node;
        if (level === 0) {
          this.$workbenApi.catList().then(res => {
            let firstNodes = res.result;
            firstNodes = firstNodes.map(item => ({
              value: item.id.toString(),
              label: item.name,
              leaf: level >= 1
            }));
            resolve(firstNodes);
          })
        } else {
          let obj = {
            parent_id: node.value
          }
          this.$workbenApi.catList(obj).then(res => {
            const secondNodes = res.result.map(item => ({
              value: item.id.toString(),
              label: item.name,
              leaf: level >= 1
            }));
            resolve(secondNodes);
          })
        }
      },
      // 编辑台本详情
      getDetails() {
        var that = this
        that.mainLoading = true
        that.$workbenApi.convertToTextDetail(that.id).then(res => {
          that.mainLoading = false
          if (res.code == 1000) {
            setTimeout(function() {
              that.form = res.result
              that.drama_list = JSON.parse(res.result.list)
              that.description = that.drama_list[that.dramaIndex].description
              that.sound_url = that.drama_list[that.dramaIndex].sound_url
              that.editCascader++
            }, 500)
          }else{
            that.$errMsg(res.message)
          }
        })
      },
      // 引用台本详情
      quoteDetails(val) {
        var that = this
        that.mainLoading = true
        that.$workbenApi.marketDetails(val).then(res => {
          that.mainLoading = false
          if (res.code == 1000) {
            setTimeout(function() {
              that.form = res.result
              that.drama_list = res.result.list
              that.description = that.drama_list[that.dramaIndex].description
              that.sound_url = that.drama_list[that.dramaIndex].sound_url
              that.editCascader++
            }, 500)
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 选择台本
      selectDrama(index) {
        this.dramaIndex = index
        if (!this.drama_list.length) return
        this.description = this.drama_list[this.dramaIndex].description
        this.sound_url = this.drama_list[this.dramaIndex].sound_url
      },
      descriptionBlur() {
        this.drama_list[this.dramaIndex].description = this.description
      },
      // 输入框搜索
      querySearchAsync(queryString, cb) {
        var drama_list = this.drama_list;
        var results = queryString ? drama_list.filter(this.createStateFilter(queryString)) : drama_list;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 3000 * Math.random());
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      handleSelect(item) {
        this.drama_list.forEach((v, i) => {
          if (v.name == item.name) {
            this.dramaIndex = i
          }
        })
      },
      //
      changeBtn(val){
        var num = val.length-1
        this.form.category_id=val[num]
      },
    },

  };
</script>

<style scoped lang="scss">
  .drama_main {
    display: flex;
    min-width: 1200px;
    font-size: 14px;

    .drama_main_l {
      width: 342px;
      height: 680px;
      background: #ffffff;
      border-radius: 10px;
      margin-right: 20px;
      padding: 20px;

      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
      }

      .line {
        width: 100%;
        height: 1px;
        background: #ededed;
        margin: 20px 0;
      }

      .cover {
        width: 160px;
        height: 210px;
        position: relative;
        margin: 0 auto 7px;

        img {
          width: 100%;
          height: 100%;
        }

        .cover_b {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 160px;
          height: 40px;
          background: rgba(0, 0, 0, 0.5);
          line-height: 40px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }

      .tit {
        color: #333333;
        line-height: 20px;
        padding-bottom: 10px;
        margin-top: 10px;

        span {
          color: #e00c25;
        }
      }

      .input {
        width: 302px;
      }
    }

    .drama_main_r {
      width: calc(100% - 362px);
      padding: 30px 90px 30px 30px;
      height: 680px;
      background: #ffffff;
      border-radius: 10px;

      .tit {
        font-size: 14px;
        color: #333333;
        padding-top: 25px;
        padding-bottom: 12px;
      }

      .audio_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        padding: 10px 20px;
        background: #f9f9f9;
        border-radius: 2px;

        audio {
          width: 100%;
          height: 50px;
        }

        .btn {
          width: 84px;
          height: 30px;
          background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
          border-radius: 2px;
          margin-left: 28px;
        }

        p {
          font-size: 14px;
          color: #333333;

          span {
            cursor: pointer;
            color: #2e4bf2;
          }
        }
      }
    }
  }

  .drama_footer {
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 0 20px;
  }

  .menu-item {
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      font-size: 14px;
      color: #333333;
      padding-left: 10px;
      display: inline-block;
      width: 40px;
    }
  }

  .drop-bottom {
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
    border-radius: 0px 0px 4px 4px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 12px;
      height: 12px;
      margin-left: 4px;
    }
  }

  .el-dropdown-menu {
    padding: 0;
  }

  .list {
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
    padding: 0 20px;
  }

  ::v-deep .tabs {
    text-align: center;

    .el-radio-button__orig-radio:checked+.el-radio-button__inner {
      color: $blueColor1 !important;
      background: rgba(46, 75, 242, 0.1);
      border-color: $blueColor1;
    }
  }

  .drama-list {
    height: 565px;
    overflow-y: auto;

    .search-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .ipt {
        width: 252px;
      }

      .plus-btn {
        margin-left: 10px;
        width: 40px;
        height: 40px;
        background: #FDFDFD;
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        span {
          font-size: 18px;
        }
      }
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      border: 1px solid transparent;
      padding: 5px 10px;
      cursor: pointer;

      .ipt {
        width: 252px;
      }

      .del-btn {
        margin-left: 5px;
        width: 32px;
        height: 32px;
        background: rgba(46, 75, 242, 0.12);
        border-radius: 20px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;

        span {
          font-size: 18px;
          color: $blueColor1;
        }
      }

      &:hover {
        border-color: #2b4afe;

      }
    }

    .list-item.active {
      border-color: #2b4afe;
    }

  }

  /* 自定义滚动条 */
  ::v-deep .drama-list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
  }

  ::v-deep .drama-list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.3);
  }

  ::v-deep .drama-list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
  }
</style>
